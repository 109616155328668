.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Div-class {
  border: white solid 1px;
  margin: 1rem;
  padding: 1rem;
}

.Header-div {
  margin: 0.5rem;
  padding: 0.5rem;
}